import React from 'react';

import './StartPage.scss'

import Header from '../../components/Header/Header';
import StartPageHero from '../../components/Hero/StartPageHero';
import Investments from '../../components/Investments/Investments';
import HeroHeadline from '../../components/Headline/HeroHeadline';



class StartPage extends React.Component {

  componentDidMount(){
    //document.body.classList.add('body--green');
  }

  componentWillUnmount(){
    //document.body.classList.remove('body--green');
  }
 

  render(){

    let investments = [
      {id: 22, name: 'Asket', url: 'https://www.asket.com', note: null},
      {id: 23, name: 'Djuce', url: 'https://www.djucewines.com', note: null},
      {id: 21, name: 'TwoTwo', url: 'https://www.twotwo-official.com', note: 'mna'},
      {id: 20, name: 'Klarna', url: 'https://www.klarna.com', note: null},
      {id: 1, name: 'General Fusion', url: 'https://generalfusion.com/', note: null},
      {id: 2, name: 'Truecaller', url: 'https://www.truecaller.com/', note: 'ipo'},
      {id: 4, name: 'Volta Greentech', url: 'https://www.voltagreentech.com/', note: null},
      {id: 5, name: 'Airly', url: 'https://airly.org/en/', note: null},
      {id: 6, name: 'ClimateView', url: 'https://www.climateview.global/', note: null},
      {id: 24, name: 'Nothing', url: 'https://nothing.tech/', note: null},
      {id: 25, name: 'Einride', url: 'https://www.einride.tech/', note: null},
      {id: 7, name: 'Airmee', url: 'https://airmee.com/', note: null},
      {id: 26, name: 'Paebbl', url: 'https://paebbl.com/', note: null},
      {id: 3, name: 'Veat', url: 'https://www.veat.se/', note: null},
      {id: 9, name: 'Places', url: 'https://www.joinplaces.co/', note: null},
      {id: 10, name: 'Revolut', url: 'https://www.revolut.com/', note: null},
      {id: 11, name: 'Nooks', url: 'https://nooks.se/', note: null},
      {id: 12, name: 'Onehundredforty', url: 'https://twitter.com/', note: 'mna'},
      {id: 13, name: 'Pale Blue Dot', url: 'https://www.paleblue.vc/', note: null},
      {id: 14, name: 'Revent', url: 'https://www.revent.vc/', note: null},
      {id: 15, name: '2050', url: 'https://2050.do/', note: null},
      {id: 16, name: 'GP Bullhound', url: 'https://www.gpbullhound.com/funds/', note: null},
      {id: 17, name: 'Greenfield', url: 'https://www.greenfield.one/', note: null},
      {id: 18, name: 'Radon Creative', url: 'https://www.valtechradon.com', note: 'mna'},
    ]
    
    return (
      <div className="start-page-wrapper">
        <Header updateMenuState={this.props.updateMenuState}/>
        <StartPageHero />
        <HeroHeadline theme="light" text="Plei invests in disruptive, smart and good-for-the-world ideas. Together with talented co-founders we also develop our own startups from scratch." arrow={true}/>
        <Investments investments={investments} />
    </div>
    )
  }
}

export default StartPage;
